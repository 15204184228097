import _ from 'lodash'
import styled from 'styled-components'
import * as colors from 'config/theme/colors'

const StyledSelect = styled.select`
  border-radius: 0;
  background-color: ${colors.backgroundColor};
  ${(p) => (p.value ? '' : `color: ${colors.placeholderTextColor};`)}
`

const Option = styled.option`
  color: ${colors.bodyFontColor};
`

const SelectInput = (props) => {
  const {
    options,
    className,
    placeholder,
    value,
    allowNullValue,
    groupOptions = false,
    groupAttribute,
    parent,
    parentLabel,
    parentValue,
    name,
    ...rest
  } = props

  const shouldGroupOptions = groupOptions && groupAttribute && parent && parentValue && parentLabel

  const renderOptions = (options) => {
    return _.map(options, (option) => {
      if (!option) return null
      let label, value
      if (typeof option === 'string') {
        label = value = option
      } else {
        label = option.label
        value = option.value
      }
      return (
        <Option
          key={value}
          value={value}
          colors={{ textColor: '#262a30' }}
        >
          {label}
        </Option>
      )
    })
  }

  /**
   * Uses parent to find the nested parent object in the children
   * Uses groupAttribute to know what attribute to group the children by
   * Uses parentValue to access what value the children are grouped by
   * Uses parentLabel to access what the group should be labelled with
   */
  const renderGroupedOptions = (options) => {
    const parents = _.chain(options)
      .map(parent)
      .compact()
      .uniqBy('id')
      .value()

    const groupedChildren = _.groupBy(options, groupAttribute)
    const groupedOptions = _.map(parents, parent => {
      const parentId = parent[parentValue]
      const children = groupedChildren[parentId]
      if (children) {
        return (
          <optgroup label={parent[parentLabel]}>
            {_.map(children, option => {
              const { value, label } = option
              return (
                <Option
                  key={value}
                  value={value}
                  colors={{ textColor: '#262a30' }}
                >
                  {label}
                </Option>
              )
            })}
          </optgroup>
        )
      }
    })

    const orphans = groupedChildren['null']
    const remainingOptions = []
    if (orphans) {
      remainingOptions.push(...renderOptions(orphans))
    }
    return [...groupedOptions, ...remainingOptions]
  }

  return (
    <StyledSelect className={className} value={value || ''} {...rest}>
      {placeholder ? (
        <Option
          value=''
          disabled={!allowNullValue}
          colors={{ textColor: '#262a30' }}
        >
          {placeholder}
        </Option>
      ) : null}
      {shouldGroupOptions ? renderGroupedOptions(options) : renderOptions(options)}
    </StyledSelect>
  )
}

export default SelectInput