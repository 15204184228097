export const iconSize = 30
export const fieldHorizontalPadding = 15
export const fontSize = 16
export const lineHeight = 22
export const inputHeight = 40
export const inputTopMargin = 10
export const formMaxWidth = 500
export const fieldMarginBottom = 25
export const labelFontSize = 18
export const labelLineHeight = 21
export const checkBoxSize = 40
export const helpTextTopMargin = 6
export const fieldErrorPaddingLeft = 20
export const fieldErrorBorderWidth = 8
export const keyFieldPadding = 30

export const iconSizeMobile = 30
export const fieldHorizontalPaddingMobile = 15
export const fontSizeMobile = 16
export const lineHeightMobile = 22
export const inputHeightMobile = 35
export const fieldMarginBottomMobile = 20
export const labelFontSizeMobile = 12
export const labelLineHeightMobile = 14
export const checkBoxSizeMobile = 35
export const inputTopMarginMobile = 7
export const helpTextTopMarginMobile = 3
export const fieldErrorPaddingLeftMobile = 16
export const fieldErrorBorderWidthMobile = 4
export const keyFieldPaddingMobile = 20
