import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'

import { grey, primary, black, hyperlink } from '../config/theme/colors'
import media from '../config/theme/media'
import { renderRichText } from '../services/contentfulService'

import H2 from './Typography/H2'
import H4 from './Typography/H4'
import P from './Typography/P'
import Link from './Link'
import Tabs from 'components/Tabs'
import Icon from 'components/Icon'
import ReturnToHomepage from './ReturnToHomepage'

/**
 * @description Block
*/
const Block = styled.div`
  max-width: 100%;
`

/**
 * @description PageBlock
*/
const PageBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  ${({ pageBlockHeight }) => pageBlockHeight ? `height: ${pageBlockHeight};` : ''}
`

/**
 * @description ContentBlock
*/
const ContentBlockOuter = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items:  flex-start;
  justify-content: space-between;
  padding: 20px;
  margin: 0;
  
  @media print {
    display: block !important;
  }

  ${({ isHomepage }) => isHomepage && `
    margin-bottom: 30px;
  `}

  ${({ isDashboard }) => isDashboard && `
    padding: 0px;
  `}

  ${media.lessThan('lg')`
    ${({ isDashboard }) => isDashboard && `

        display: block;
        flex-direction: column;

    `}
  `}

  ${media.greaterThan('lg')`
  ${({ isDashboard }) => isDashboard && `
    padding-left: 10%;
  `}
`}

  ${media.lessThan('md')`
    ${({ isDashboard }) => !isDashboard && `

        display: block;
        flex-direction: column;

    `}
  `}
`

const ContentBlockInner = styled(Block)`
  display: flex;
  flex: 1;
  flex-direction: ${({ row, grid }) => row || grid ? 'row' : 'column'};
  flex-wrap: ${({ wrap, grid }) => wrap || grid ? 'wrap' : 'unset'};
  align-items: ${(p) => p.alignItems || 'flex-start'};
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;

  ${media.lessThan('md')`
    flex-wrap: nowrap;
    flex-direction: column;
  `}

  ${({ center }) => center ? `
    justify-content: center;
    align-items: center;
  ` : ''}

  ${({ isTabBlock }) => isTabBlock ? `
    align-items: center;

  ` : ''}
  ${media.lessThan('md')`
    ${({ isTabBlock }) => isTabBlock ? `
      align-items: center;
      flex-direction: row;
    ` : ''}
  `}
  
`

const ContentBlockSpacing = styled(Block)`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.forEach({
  values: { sm: ['0', '0'], md: ['20', '10'], lg: ['20', '10'] },
  getStyle: (val) => `padding: ${val[1]}px ${val[0]}px ${val[0]}px ${val[0]}px;`
})}
  
  ${({ isFullWidthContentBlock, isDashboard }) => isFullWidthContentBlock ? 
  `
    width: 5%;
  `:

    (isDashboard? 
      media.forEach({
        values: { sm: '100', md: '100', lg: '0' },
        getStyle: (val) => `width: ${val}%;`
      })
      
      :
      
      media.forEach({
        values: { sm: '100', md: '15', lg: '15' },
        getStyle: (val) => `width: ${val}%;`
      })
    )
  }
`

const ReturnToHomepageButton = styled(ReturnToHomepage)`
  margin: 40px 0 20px 0;
`

const ContentBlock = ({
  children,
  leftSpacingChild,
  rightSpacingChild,
  row,
  wrap,
  grid,
  center,
  showReturnToHomepage,
  isTabBlock,
  isFullWidthContentBlock,
  isDashboard,
  ...props
}) => {

  return (
    <ContentBlockOuter {...props} isDashboard={isDashboard}>
      <ContentBlockSpacing isFullWidthContentBlock={isFullWidthContentBlock} isDashboard={isDashboard}>
        {leftSpacingChild}
      </ContentBlockSpacing>
      <ContentBlockInner row={row} wrap={wrap} grid={grid} center={center} isTabBlock={isTabBlock}>
        {children}
        {
          showReturnToHomepage && (
            <ReturnToHomepageButton />
          )
        }
      </ContentBlockInner>
      <ContentBlockSpacing isFullWidthContentBlock={isFullWidthContentBlock}>
        {rightSpacingChild}
      </ContentBlockSpacing>
    </ContentBlockOuter>
  )
}

/**
 * @description GridContentBlock
*/
const GridContentBlock = ({ children, ...props }) => {
  return (
    <ContentBlock grid {...props}>
      {children}
    </ContentBlock>
  )
}

/**
 * @description HeaderBlock
*/
const HeaderBlock = styled(ContentBlock)`
  background-color: ${grey()};
  ${({ isHomepage }) => isHomepage
    ? 'padding: 30px 20px 50px 20px;'
    : 'padding: 20px 20px 30px 20px;'
  }
  
  ${media.lessThan('md')`
    ${({ isHomepage }) => isHomepage
      ? 'padding: 15px 20px 25px 20px;'
      : 'padding: 5px 20px 15px 20px;'
    }
  `}
`

const StyledTab = styled(Tabs)`
  display: flex;
  padding: 0px;
`

/**
 * @description FullWidthBlock
*/
const FullWidthBlock = styled(Block)`
  display: flex;
  flex-direction: ${({ row }) => row ? 'row' : 'column'};
  width: 100%;
  align-items: ${({ stretch, justifyCenter }) => stretch ? 'stretch' : (justifyCenter ? 'center' : 'flex-start')};
  justify-content: flex-start;
  padding: 0;
  margin: 0;
`

/**
 * @description ContentBlock
*/
const TabbedHeaderBlockOuter = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items:  flex-start;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  margin: 0 0 20px 0;
  background-color: ${grey()};

  ${media.lessThan('md')`
    display: block;
    flex-direction: column;
    margin: 0;
  `}
`

const TabbedHeaderBlockInner = styled(Block)`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: ${({ wrap, grid }) => wrap || grid ? 'wrap' : 'unset'};
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  z-index: 0;

  ${media.lessThan('md')`
    flex-wrap: nowrap;
  `}
`

const TabbedHeaderBlockSpacing = styled(Block)`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.forEach({
  values: { sm: ['0', '0'], md: ['20', '10'], lg: ['20', '10'] },
  getStyle: (val) => `padding: ${val[1]}px ${val[0]}px ${val[0]}px ${val[0]}px;`
})}
  ${media.forEach({
  values: { sm: '100', md: '15', lg: '15' },
  getStyle: (val) => `width: ${val}%;`
})}
`

const TabbedHeaderFullWidthBlock = styled(FullWidthBlock)`
  align-items: center;
  flex-direction: row;
  margin: 40px 0;

  ${media.lessThan('md')`
    margin: 0px 0 20px 0;
  `}
`

const TabbedHeaderBlock = ({
  children,
  leftSpacingChild,
  rightSpacingChild,
  row,
  wrap,
  grid,
  center,
  tabs,
  mobileSelect,
  onClick,
  ...props
}) => {
  return (
    <TabbedHeaderBlockOuter {...props}>
      <TabbedHeaderBlockSpacing>
        {leftSpacingChild}
      </TabbedHeaderBlockSpacing>
      <TabbedHeaderBlockInner row={row} wrap={wrap} grid={grid} center={center}>
        <TabbedHeaderFullWidthBlock>
          {children}
        </TabbedHeaderFullWidthBlock>
        <FullWidthBlock>
          <StyledTab
            tabs={tabs}
            mobileSelect={mobileSelect}
            onClick={onClick}
          />
        </FullWidthBlock>
      </TabbedHeaderBlockInner>
      <TabbedHeaderBlockSpacing>
        {rightSpacingChild}
      </TabbedHeaderBlockSpacing>
    </TabbedHeaderBlockOuter>
  )
}

/**
 * @description HalfWidthBlock
*/
const HalfWidthBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  ${({ index }) => _.isNumber(index) && index % 2 === 0
    ? 'margin-right: 20px;'
    : 'margin-left: 20px;'
  }
  ${media.lessThan('md')`
    width: 100%;
    margin: 0;
  `}
`

/**
 * @description GridBlock
*/
const GridTitleContainer = styled(FullWidthBlock)`
  box-shadow : 0 3px 0 ${grey()};
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.lessThan('md')`
    padding: 0 0 10px 0;
  `}
`

const GridTitle = styled(H2)`
  margin: 0;
`

const ResourceContainer = styled.div`
  padding: 10px 0 20px 0;
  ${media.lessThan('md')`
    padding: 0 0 10px 0;
  `}
`

const GridBlock = ({ children, title, content, index, titleLinkName, titleLinkHref, ...props }) => {
  return (
    <HalfWidthBlock index={index}>
      <GridTitleContainer>
        <GridTitle>{title}</GridTitle>
        {
          titleLinkName && titleLinkHref && (
            <Link href={titleLinkHref} noWrap>{titleLinkName}</Link>
          )
        }
      </GridTitleContainer>
      <ResourceContainer>
        {
          content
            ? (<ResourceBlock content={content} />)
            : children
        }
      </ResourceContainer>
    </HalfWidthBlock>
  )
}

/**
 * @description ResourceBlock
*/
const ResourceBlock = ({ content, ...props }) => {
  return (
    <Block {...props}>
      {renderRichText(content)}
    </Block>
  )
}

/**
 * @description HighlightBlock
*/
const HighlightBlockOuter = styled(Block)`
  // display: flex;
  // flex: 1;
  display: block;
  border-left: 8px solid ${primary()};
  padding: 0 0 0 20px;
  justify-content: center;
  align-items: center;
`

const HighlightBlockInner = styled(Block)`
  // display: flex;
  // flex: 1;
  display: block;
  flex-direction: ${({ row }) => row ? 'row' : 'column'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 10px 10px 0;
`

const HighlightBlock = ({ children, ...props }) => {
  return (
    <HighlightBlockOuter>
      <HighlightBlockInner {...props}>
        {children}
      </HighlightBlockInner>
    </HighlightBlockOuter>
  )
}

/**
 * @description NewsSummaryBlock
*/
const NewsSummaryBlockOuter = styled(Block)`
  margin-bottom: 35px;
  margin-top: 16px;
`

const NewsTitle = styled(H4)`
  margin: 0;
  font-weight: bold;
  letter-spacing: 0;
  display: inline-block;
  color: ${hyperlink()};
  &:hover {
    color: ${black()};
  }
  ${media.forEach({
  values: { sm: '12', md: '15', lg: '18' },
  getStyle: (val) => `font-size: ${val}px;`
})}
`
const NewsDescription = styled(P)`
`
const ModifiedDate = styled(P)`
  font-size: 10px;
  ${media.greaterThan('md')`
    font-size: 14px;
  `}
`
const NewsLink = styled(Link)`
  display: flex;
  align-items: center;
`
const IconWrap = styled.span`
  margin-left: 5px;
  display:inline-block;
`

const NewsSummaryBlock = ({ title, description, publishedAt, updatedAt, href, newPageIfExternal }) => {
  let isUpdated = false
  if (moment(updatedAt).isAfter(moment(publishedAt))) {
    isUpdated = true
  }

  return (
    <NewsSummaryBlockOuter>
      <NewsLink href={href} color={hyperlink()} newPageIfExternal={newPageIfExternal}>
        <NewsTitle>
          {title}
          {newPageIfExternal && (
            <IconWrap>
              <Icon
                name={"newTab"}
                aria-hidden
                size={14}
              />
            </IconWrap>
          )}
        </NewsTitle>
      </NewsLink>
      <NewsDescription>{description}</NewsDescription>
      <ModifiedDate>
        {`${isUpdated ? 'Updated' : 'Published'}: ${moment(isUpdated ? updatedAt : publishedAt).format('D MMMM, YYYY')}`}
      </ModifiedDate>
    </NewsSummaryBlockOuter>
  )
}


/**
 * @description ColumnGrid
*/
const ColumnGridOuter = styled.div`
  display: grid;
  grid-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: repeat(${({ gridSize }) => gridSize ? gridSize : 1}, 1fr);
  align-content: start;
  width: 100%;

  ${media.lessThan('md')`
    grid-column: span ${({ gridSize }) => gridSize ? gridSize : 1};
  `}
`

const ColumnGrid = ({ gridSize, children }) => {
  return (
    <ColumnGridOuter gridSize={gridSize}>
      {children}
    </ColumnGridOuter>
  )
}

/**
 * @description ColumnGridBlock
*/
const ColumnGridBlockOuter = styled.div`
  grid-column: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.greaterThan('md')`
    grid-column: span ${({columnSize}) => columnSize ? columnSize : '1'};
  `}

  ${({customStyles}) => customStyles ? customStyles : ''}
`
const StyledLink = styled(Link)`
  ${media.lessThan('md')`
    font-size: 13px;
  `}
`

const ColumnGridBlock = ({ columnSize, title, titleLinkName, titleLinkHref, children, customStyles }) => {
  return (
    <ColumnGridBlockOuter columnSize={columnSize} customStyles={customStyles}>
      <GridTitleContainer>
        <GridTitle>{title}</GridTitle>
        {
          titleLinkName && titleLinkHref && (
            <StyledLink href={titleLinkHref} noWrap>{titleLinkName}</StyledLink>
          )
        }
      </GridTitleContainer>

      {children}
    </ColumnGridBlockOuter>
  )
}


export {
  Block,
  PageBlock,
  ContentBlock,
  HeaderBlock,
  TabbedHeaderBlock,
  FullWidthBlock,
  HalfWidthBlock,
  GridBlock,
  ResourceBlock,
  HighlightBlock,
  GridContentBlock,
  NewsSummaryBlock,
  ColumnGrid,
  ColumnGridBlock
}