import styled from 'styled-components'

import icons from '../assets'

const IconWrapper = styled.div`
  height: ${({ $size, $height }) => $height || $size}px;
  width: ${({ $size, $width }) => $width || $size}px;
  display: flex;
  overflow: visible;
`

const Icon = ({ name, size = 20, width, height, ...rest }) => {
  const Icon = icons[name]
  return (
    <IconWrapper $size={size} $width={width} $height={height} {...rest}>
      <Icon height={height || size} width={width || size}/>
    </IconWrapper>
  )
}

export default Icon
