import _ from 'lodash'
import FieldBase from './common/FieldBase'
import InputBase from './common/InputBase'
import SelectInput from './common/SelectInput'

const renderField = (props) => {
  const selectProps = _.omit(props, ['type'])
  return <InputBase {...selectProps} component={SelectInput} icon={'selectArrows'} iconAlign={'right'} iconHeight={16} iconWidth={24} />
}

const Select = (props) => {
  return <FieldBase {...props} renderField={renderField} />
}

export default Select
