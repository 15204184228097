import styled, { keyframes } from 'styled-components'
import VisuallyHidden from '@reach/visually-hidden'

import useTranslate from 'hooks/useTranslate'
import * as colors from 'config/theme/colors'

// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CircularProgress/CircularProgress.js

const SIZE = 44

const circularDashKF = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

const circularRotateKF = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: block;
  animation: ${circularRotateKF} 1.8s linear infinite;
`

const Circle = styled.circle`
  stroke: currentColor;
  fill: none;
`

const ForegroundCircle = styled(Circle)`
  animation: ${circularDashKF} 1.8s ease-in-out infinite;
  strokedasharray: 80px, 200px;
  strokedashoffset: 0px;
`

const BackgroundCircle = styled(Circle)`
  opacity: 0.25;
`

const CircularProgress = (props) => {
  const { color, size = 40, thickness = 5, a11yText, className } = props
  const translate = useTranslate()

  const circleProps = {
    cx: SIZE,
    cy: SIZE,
    r: (SIZE - thickness) / 2,
    strokeWidth: thickness
  }

  return (
    <Wrapper
      style={{
        width: size,
        height: size,
        color: process.env.primaryColor
      }}
      aria-live='assertive'
      role='progressbar'
      className={className}
    >
      <svg
        style={{ display: 'block' }}
        viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
      >
        <BackgroundCircle {...circleProps} />
        <ForegroundCircle {...circleProps} />
      </svg>
      <VisuallyHidden>
        <p>{a11yText || translate('LOADING_DATA')}</p>
      </VisuallyHidden>
    </Wrapper>
  )
}

export default CircularProgress
