import useTranslate from '../hooks/useTranslate'

import Link from './Link'
import Button from './Button'

const ReturnToHomepage = ({ ...props }) => {
  const translate = useTranslate()
  return (
    <Link href={'/'} noDecoration>
      <Button { ...props }>
        {translate('RETURN_HOME')}
      </Button>
    </Link>
  )
}

export default ReturnToHomepage
