import styled, { css } from 'styled-components'
import _isObject from 'lodash/isObject'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _map from 'lodash/map'

import media from 'config/theme/media'

// height and width can be either a number or an object
// where the keys are breakpoints and the values are numbers

const Spacing = styled((props) => (
  <div {..._omit(props, ['height', 'width'])} />
))`
  flex-shrink: 0;
  ${(props) => {
    return _map(_pick(props, ['width', 'height']), (value, key) => {
      if (value) {
        if (_isObject(value)) {
          return css`
            ${media.forEach({
              values: value,
              getStyle: (x) => `${key}: ${x}px`
            })}
          `
        } else {
          return `${key}: ${value}px`
        }
      }
    })
  }}
`

export default Spacing
