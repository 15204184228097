import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledIntro = styled.div`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: normal;
  letter-spacing: 0;
  width: 100%;

  ${({ small }) => small
    ? media.forEach({
      values: { sm: '12', md: '15', lg: '18' },
      getStyle: (val) => `font-size: ${val}px;`
    })
    : media.forEach({
      values: { sm: '13', md: '16.5', lg: '20' },
      getStyle: (val) => `font-size: ${val}px;`
    })
  }

  ${({ small }) => small
    ? media.forEach({
      values: { sm: '18', md: '22', lg: '27' },
      getStyle: (val) => `line-height: ${val}px;`
    })
    : media.forEach({
      values: { sm: '18', md: '24', lg: '30' },
      getStyle: (val) => `line-height: ${val}px;`
    })
  }
  
  ${({ isSummary }) => isSummary && `
    margin-bottom: 30px;
  `}
`

const Intro = ({ children, ...props }) => {
  return (
    <StyledIntro {...props}>{children}</StyledIntro>
  )
}

export default Intro
