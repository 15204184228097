import dropdownArrow from './DropdownArrow.svg'
import email from './Email.svg'
import eye from './Eye.svg'
import hideEye from './HideEye.svg'
import lock from './Lock.svg'
import phone from './Phone.svg'
import search from './Search.svg'
import star from './Star.svg'
import tick from './Tick.svg'
import user from './User.svg'
import selectArrows from './SelectArrows.svg'
import close from './Close.svg'
import bin from './Bin.svg'
import newTab from './NewTab.svg'
import info from './InfoIcon.svg'
import description from './Description.svg'

export default {
  dropdownArrow,
  email,
  eye,
  hideEye,
  lock,
  phone,
  search,
  star,
  tick,
  user,
  login: user,
  signUp: star,
  selectArrows,
  close,
  newTab,
  info,
  bin,
  description
}
