import _pick from 'lodash/pick'
import _isString from 'lodash/isString'
import _isEmpty from 'lodash/isEmpty'

const isString = (value) => {
  return _isString(value) && !_isEmpty(value)
}

const getAriaDescribedby = (props) => {
  const { errorMessage, name, helpId, 'aria-describedby': ariaDescribedby } = props

  if (isString(ariaDescribedby)) {
    return ariaDescribedby
  }

  if (isString(errorMessage)) {
    return `error_${name}`
  }

  if (isString(helpId)) {
    return helpId
  }
}

export default (props) => {
  const {
    icon,
    errorMessage,
    label,
    name,
    date,
    required,
    hidePlaceholder,
    placeholder,
    helpId,
    ...rest
  } = props

  return {
    ..._pick(rest, [
      'value',
      'onChange',
      'options',
      'type',
      'autoComplete',
      'readOnly',
      'disabled',
      'rows',
      'allowNullValue',
      'autoFocus',
      'onKeyPress',
      'iconAlign',
      'disabled',
      'groupOptions',
      'groupAttribute',
      'parent',
      'parentLabel',
      'parentValue'
    ]),
    'data-has-icon': !!icon,
    'aria-invalid': !!errorMessage,
    'aria-label': label,
    'aria-required': !!required,
    'aria-describedby': getAriaDescribedby(props),
    placeholder: hidePlaceholder ? '' : !date && placeholder,
    name
  }
}
