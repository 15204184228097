import styled from 'styled-components'
import vars from 'config/theme/vars'
import media from 'config/theme/media'
import { horizontalPadding, verticalPadding } from 'utils/styles'

const Container = styled.div`
  margin: 0 auto;
  white-space: pre-wrap;
  max-width: ${vars.maxWidth}px;
  width: 100%;
  ${(p) =>
    p.horizontalGutter !== false
      ? media.forEach({
          values: vars.pageGutter,
          getStyle: (x) => horizontalPadding(x + 'px')
        })
      : ''}
  ${(p) =>
    p.verticalGutter
      ? media.forEach({
          values: vars.pageGutter,
          getStyle: (x) => verticalPadding(x + 'px')
        })
      : ''}
  ${(p) =>
    p.bottomGutter
      ? media.forEach({
          values: vars.pageGutter,
          getStyle: (x) => `padding-bottom: ${x}px`
        })
      : ''}
  ${(p) =>
    p.topGutter
      ? media.forEach({
          values: vars.pageGutter,
          getStyle: (x) => `padding-top: ${x}px`
        })
      : ''}
`

export default Container
