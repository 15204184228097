import styled, { css } from 'styled-components'

import media from 'config/theme/media'

const greaterThan = media.greaterThan

const Show = styled.div`
  ${(p) => {
    const { gt, lt } = p
    if (gt && lt) {
      return css`
        display: none;
        ${greaterThan(gt)`display: block;`}
        ${greaterThan(lt)`display: none;`}
      `
    }
    if (gt) {
      return css`
        display: none;
        ${greaterThan(gt)`display: block;`}
      `
    }
    if (lt) {
      return css`
        display: block;
        ${greaterThan(lt)`display: none;`}
      `
    }
  }}
`

export default Show
