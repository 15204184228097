import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledSpan = styled.span`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  ${({ small, large }) => small
    ? media.forEach({
      values: { sm: '10', md: '12', lg: '14' },
      getStyle: (val) => `font-size: ${val}px;`
    })
    : (
      large
        ? media.forEach({
          values: { sm: '12', md: '15', lg: '18' },
          getStyle: (val) => `font-size: ${val}px;`
        })
        : media.forEach({
          values: { sm: '12', md: '14', lg: '16' },
          getStyle: (val) => `font-size: ${val}px;`
        })
    )
  }
  letter-spacing: 0;
`

const Span = ({ children, ...props }) => {
  return (
    <StyledSpan {...props}>{children}</StyledSpan>
  )
}

export default Span
