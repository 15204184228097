import { Fragment, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import { TabList, Tab, Tabs, TabPanels, TabPanel } from '@reach/tabs'
import Spacing from 'components/Spacing'
import Icon from 'components/Icon'
import Box from 'components/Box'
import * as colors from 'config/theme/colors'
import Show from 'components/Show'
import SelectField from 'components/Fields/Select'
import useTranslate from 'hooks/useTranslate'
import media from 'config/theme/media'

const iconSize = 28

const SelectFieldWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`
const TabListComp = (props) => {
  const { mobileSelect, showTabs, staticContext, panel, inputtype, ...rest } =
    props
  return <TabList {...rest} />
}

const TabListStyle = styled(TabListComp)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const TabLink = React.forwardRef((props, ref) => {
  // excluded these props to prevent console warnings
  const {
    children,
    selectedIndex,
    _selectedPanelRef,
    _onFocusPanel,
    _onSelectTab,
    staticContext,
    inputtype,
    mobileSelect,
    ...rest
  } = props

  return (
    <span ref={ref} {...rest}>
      {children}
    </span>
  )
})

const TabLinkStyle = styled(TabLink)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  padding: 20px 30px;
  ${media.lessThan('lg')`
    padding: 10px 20px;
  `}
  text-decoration: none;
  &[data-selected] {
    background-color: ${colors.white()};
    border-bottom-color: transparent;
  }
  &:not([data-selected]) {
    text-decoration: underline;
    color: ${colors.hyperlink()};
  }
  ${media.forEach({
  values: { sm: '12', md: '16.5', lg: '20' },
  getStyle: (val) => `font-size: ${val}px;`,
})}
`

const TabWrapper = (props) => {
  const { isSelected, children } = props
  return (
    <Tab {...props} as={TabLinkStyle}>
      {children}
    </Tab>
  )
}

const renderTabs = ({ pathname, tabs, tabId }) =>
  _map(tabs, (tab, i) => {
    const { label, icon } = tab

    return (
      <TabWrapper key={`${i}|to`}>
        {icon ? (
          <>
            <Icon name={icon} size={iconSize} />
            <Spacing height={10} />
          </>
        ) : null}
        <span>{label}</span>
      </TabWrapper>
    )
  })

const renderTabPanels = ({ panel, tabs, tabIndex, ...rest }) => {
  return _map(tabs, (tab, i) => {
    return tabIndex === i ? (
      <TabPanel style={{ width: '100%' }} key={i}>
        {panel}
      </TabPanel>
    ) : (
      <Fragment key={i} />
    )
  })
}

const MobileComp = (props) => <Mobile {...props} />

const mobileDropdown = ({ mobileSelect }) =>
  mobileSelect && (
    <SelectFieldWrapper>
      <SelectField
        options={_.get(mobileSelect, 'options', '')}
        value={_.get(mobileSelect, 'value', '')}
        onChange={_.get(mobileSelect, 'onChange', '')}
      />
    </SelectFieldWrapper>
  )

const Mobile = (props) => {
  const { mobileSelect, staticContext, panel, pathname, tabId, tabs, ...rest } =
    props
  return (
    <>
      <Show gt="lg">
        <TabListStyle {...rest}>
          {renderTabs({ pathname, tabs, tabId, ...rest })}
        </TabListStyle>
      </Show>
      <Box noBox>
        <Show lt="lg" style={{ width: '100%' }}>
          {mobileDropdown({ mobileSelect })}
        </Show>
        <TabPanels style={{ width: '100%' }} {...rest}>
          {renderTabPanels({ panel, tabs, ...rest })}
        </TabPanels>
      </Box>
    </>
  )
}

const DesktopPanels = (props) => {
  const {
    mobileSelect,
    showTabs,
    staticContext,
    panel,
    pathname,
    tabs,
    tabId,
    ...rest
  } = props
  return (
    <>
      <TabListStyle {...rest}>
        {renderTabs({ pathname, tabs, tabId, ...rest })}
      </TabListStyle>
      <TabPanels {...rest}>
        {renderTabPanels({ panel, tabs, ...rest })}
      </TabPanels>
    </>
  )
}
const Desktop = (props) => <DesktopPanels {...props} />

function TabsComponent(props) {
  // expects tabs prop to be array of objects with properties of {label, to, icon}
  // expects panel prop to be a single functional component, the main content of the panel
  // since the way we are populating the tab panel is to fetch data for selected tab
  // expects mobileSelect prop to be false or object with properties {options, values, onChange}
  const { mobileSelect = false, showTabs = true, router, ...rest } = props
  const translateUrl = useTranslate()

  const { pathname, query } = router || {}
  const [tabIndex, setTabIndex] = useState(0)

  React.useEffect(() => {
    const index = _.findIndex(rest.tabs, (tab) => {
      return tab.isSelected === true
    })
    index > -1 ? setTabIndex(index) : setTabIndex(0)
  }, [rest.tabs])

  const tabId = _get(query.tab)

  const renderLogic = (props) => {
    const { mobileSelect, showTabs, ...rest } = props
    return (
      <Tabs
        style={{ width: '100%' }}
        index={tabIndex}
        onChange={(index, ...args) => {
          setTabIndex(index)
          const to = _.get(rest, ['tabs', index, 'to'])
          if (to) {
            router.replace(translateUrl(to))
          } else {
            if (_.isFunction(rest.onClick)) {
              rest.onClick(index)
            }
          }
        }}
      >
        {mobileSelect ? (
          MobileComp({ mobileSelect, ...rest })
        ) : showTabs ? (
          Desktop({ ...rest })
        ) : (
          <Fragment />
        )}
      </Tabs>
    )
  }
  return renderLogic({
    mobileSelect,
    showTabs,
    pathname,
    tabId,
    tabIndex,
    ...rest,
  })
}
export default withRouter(TabsComponent)
