// PURPOSE OF INPUTBASE:
// To contain common input styles such as font-size and padding and border
// and add an icon to the left of the input

import { Fragment } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'

import Icon from 'components/Icon'
import * as colors from 'config/theme/colors'
import media from 'config/theme/media'
import { iconSize, fieldHorizontalPadding, fontSize, fontSizeMobile, inputHeight, inputHeightMobile } from './vars'
import getInputProps from './getInputProps'

const InputWrapper = styled.div`
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  display: flex;
  flex: 1 1 auto;
`

const InputIconWrap = styled.div`
  color: ${({ theme }) => (theme.color ? theme.color : colors.black())};
  position: absolute;
  ${({ iconAlign }) => {
    if (iconAlign === 'left') {
      return 'left: 0;'
    }
    if (iconAlign === 'right') {
      return 'right: 0;'
    }
  }}
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

const RightAlign = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled.input`
  display: block;
  font-family: inherit;
  color: ${({ theme }) => (theme.color ? theme.color : colors.black())};
  font-size: ${fontSizeMobile}px;
  height: ${inputHeightMobile}px;
  line-height: ${inputHeightMobile}px;
  ${({ iconAlign, ...p }) => {
    const leftPadding = p['data-has-icon'] && iconAlign === 'left' ? iconSize + fieldHorizontalPadding : fieldHorizontalPadding
    const rightPadding = p['data-has-icon'] && iconAlign === 'right' ? iconSize + fieldHorizontalPadding : fieldHorizontalPadding
    return `padding: 0 ${rightPadding}px 0 ${leftPadding}px;`
  }}
  margin: 0 auto;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid
    ${(p) =>
      p['aria-invalid']
        ? _get(p, 'theme.errorColor') || colors.errorTextColor
        : _get(p, 'theme.borderColor') || colors.inputBorderColor};
  border-radius: 0;
  background-color: ${({ theme }) =>
    theme.backgroundColor ? theme.backgroundColor : 'inherit'};
  caret-color: ${({ theme }) => (theme.caretColor ? theme.caretColor : 'inherit')};
  &::-ms-expand {
    display: none;
  }
  &::placeholder {
    color: ${colors.placeholderTextColor};
  }
  &:focus {
    ${({ theme }) =>
      theme.focusColor
        ? `outline: none;
           border: 2px solid rgba(255, 255, 255, 1);`
        : ''};
  }

  ${media.greaterThan('md')`
    font-size: ${fontSize}px;
    height: ${inputHeight}px;
    line-height: ${inputHeight}px;
  `}

  ${(p) => p.disabled && 'background-color: #dadada;'}
`

const InputBase = (props) => {
  const { icon, component, inlineRight, iconAlign = 'left', iconHeight, iconWidth, disabled } = props
  return (
    <Fragment>
      <InputWrapper>
        <Input {...getInputProps(props)} as={component} id={props.id} disabled={disabled} />
        {icon ? <InputIconWrap iconAlign={iconAlign}><Icon name={icon} size={iconSize} width={iconWidth} height={iconHeight}/></InputIconWrap> : null}
        <RightAlign>
          {inlineRight ? (
            <inlineRight.component {...inlineRight.props} />
          ) : null}
        </RightAlign>
      </InputWrapper>
    </Fragment>
  )
}

export default InputBase
