// PURPOSE OF FIELDBASE:
// To add a label and an error message, and spread the 'field' prop
// that comes from Formik

import styled from 'styled-components'
import _pick from 'lodash/pick'

import VisuallyHidden from '@reach/visually-hidden'
import * as colors from 'config/theme/colors'

import media from 'config/theme/media'
import useTranslate from 'hooks/useTranslate'
import Url from 'components/Url'
import {
  fieldMarginBottom,
  fieldMarginBottomMobile,
  labelFontSize,
  labelFontSizeMobile,
  labelLineHeight,
  labelLineHeightMobile,
  inputTopMargin,
  inputTopMarginMobile,
  helpTextTopMargin,
  helpTextTopMarginMobile,
  fieldErrorPaddingLeft,
  fieldErrorPaddingLeftMobile,
  fieldErrorBorderWidth,
  fieldErrorBorderWidthMobile,
  keyFieldPadding,
  keyFieldPaddingMobile
} from './vars'

export const Label = styled.div`
  font-size: ${labelFontSizeMobile}px;
  line-height: ${labelLineHeightMobile}px;
  display: block;
  padding: 0;
  text-align: left;
  max-width: 100%;
  font-weight: ${({ keyQuestion }) => keyQuestion ? 'bold' : 'normal'};
  ${media.greaterThan('md')`
    font-size: ${labelFontSize}px;
    line-height: ${labelLineHeight}px;
  `}
`

const Optional = styled.small`
  font-size: 14px;
  margin-left: 5px;
`
const Field = styled.div`
  background: ${(props) => props.keyQuestion ? colors.grey() : 'transparent'};
  padding: ${(props) => props.keyQuestion ? `${keyFieldPaddingMobile}px` : '0'};
  margin: 0 0 ${fieldMarginBottomMobile}px 0;
  ${(p) => {
    const w = p['data-width']
    let values
    if (typeof w === 'object') {
      values = w
    } else {
      values = { sm: w || 1 }
    }
    return media.forEach({
      values,
      getStyle: (x) => `width: ${100 * x}%;`
    })
  }}
  padding-left: ${(p) => p['data-error'] ? `${fieldErrorPaddingLeftMobile}px` : p.keyQuestion ? `${keyFieldPaddingMobile}px` : '0'};
  border-left: ${(p) => p['data-error'] ? `${fieldErrorBorderWidthMobile}px solid ${colors.errorTextColor}` : '0'};
  ${media.greaterThan('md')`
    padding: ${(props) => props.keyQuestion ? `${keyFieldPadding}px` : '0'};
    margin: 0 0 ${fieldMarginBottom}px 0;
    padding-left: ${(p) => p['data-error'] ? `${fieldErrorPaddingLeft}px` : p.keyQuestion ? `${keyFieldPadding}px` : '0'};
    border-left: ${(p) => p['data-error'] ? `${fieldErrorBorderWidth}px solid ${colors.errorTextColor}` : '0'};
  `}
`

const Wrapper = styled.div`
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
`

const FieldWrapper = styled.div`
  margin-top: ${(props) => props.noMargin ? 0 : inputTopMarginMobile}px;
  ${media.greaterThan('md')`
    margin-top: ${(props) => props.noMargin ? 0 : inputTopMargin}px;
  `}
`

const HelpText = styled.p`
  font-size: ${labelFontSizeMobile}px;
  margin: ${helpTextTopMarginMobile}px 0 0 0;
  line-height: ${labelLineHeightMobile}px;
  text-align: left;
  color: ${colors.inputHelpTextColor};
  ${media.greaterThan('md')`
    margin: ${helpTextTopMargin}px 0 0 0;
    font-size: ${labelFontSize}px;
    line-height: ${labelLineHeight}px;
  `}
`

const ErrorMessage = styled(HelpText)`
  color: ${colors.errorTextColor};
`

const FieldBase = (props) => {
  const {
    field,
    form,
    label,
    renderField,
    hideLabel,
    leftAlign,
    width,
    date,
    noLabel,
    noErrorMessage,
    helpText,
    labelLink,
    legendLink,
    keyQuestion,
    legend,
    ...rest
  } = props

  const translate = useTranslate()

  const { name } = field || {}
  const { errors, touched, setFieldValue } = form || {}

  const errorMessage =
    touched && touched[name] && errors && errors[name] ? errors[name] : ''

  const fieldProps = {
    id: name,
    ..._pick(rest, [
      'value',
      'onChange',
      'options',
      'type',
      'autoComplete',
      'hidePlaceholder',
      'component',
      'icon',
      'name',
      'readOnly',
      'disabled',
      'inlineRight',
      'rows',
      'allowNullValue',
      'autoFocus',
      'onKeyPress',
      'required',
      'onClick',
      'placeholder',
      'iconAlign',
      'helpId',
      'groupOptions',
      'groupAttribute',
      'parent',
      'parentLabel',
      'parentValue'
    ]),
    errorMessage,
    label,
    setFieldValue,
    labelLink,
    legendLink,
    ...field
  }
  return (
    <Field keyQuestion={keyQuestion} data-width={width} data-error={errorMessage}>
      <Wrapper
        as={legend ? 'fieldset' : 'div'}
      >
        {legend ? (
          <Label keyQuestion={keyQuestion} as={'legend'}>
            {legendLink ? <Url text={translate(legend)} url={legendLink} /> : legend}
          </Label>
        ) : null}
        {!hideLabel ? (
          <Label htmlFor={name} as={'label'}>
            {labelLink ? <Url text={translate(label)} url={labelLink} /> : label}
            {!fieldProps.required && <Optional>{`(${translate('GENERIC_FORM_OPTIONAL')})`}</Optional>}
          </Label>
        ) : noLabel ? null : (
          <VisuallyHidden>{label}</VisuallyHidden>
        )}
        {helpText ? <HelpText>{helpText}</HelpText> : null}
        {form && !noErrorMessage && errorMessage ? (
          <ErrorMessage role={'alert'} id={`error_${name}`}>{translate(errorMessage, { field: legend || label })}</ErrorMessage>
        ) : null}
        <FieldWrapper noMargin={(fieldProps.type === 'button')}>
          {renderField ? renderField(fieldProps) : null}
        </FieldWrapper>
      </Wrapper>
    </Field>
  )
}

export { Wrapper }
export default FieldBase
